import React from 'react'
import ContactForm from '../contact/ContactForm'
import { FaRegStar } from 'react-icons/fa';

import './DepressionSection.css'

const DepressionSection = () => {
  return (
<>
<div className="depression-section-header container">
                <h1>DEPRESSION</h1><br/>
                <p>A common but serious mood disorder, depression can affect one’s overall behavior, sleep, eating, or ability to perform day-to-day tasks and obligations. There are several types of depression, but the most commonly diagnosed is Major Depressive Disorder (or Clinical Depression). Depression can occur in people from all walks of life, including those that suffer from Bipolar Disorder, and during or after pregnancy for females.</p> <br/>
                <p>It is much more than just feeling sad or having a bad day. Everyone experiences that. According to the CDC.gov, 1 in 6 adults experience depression, but it can happen to any age and any person. Many people with depression also suffer from other medical issues.<br/>
                Ranked as the third cause of Major depressive disorder (MDD) is the most severe form of depression. The most severe cases of MDD can result in suicide. <br/><br/>
                <p>There is clinical depression, and then there is Major Depressive Disorder. Symptoms of Major Depressive Disorder include:</p>
                <br/>
                <ul>
                <li><FaRegStar className='star'/> Angry outbursts/Irritability</li>
                <li><FaRegStar className='star'/> Loss of interest in favorite activities</li>
                <li><FaRegStar className='star'/> Fatigue</li>
                <li><FaRegStar className='star'/> Anxiety/Agitation/Restlessness</li>
                <li><FaRegStar className='star'/> Trouble sleeping</li>
                <li><FaRegStar className='star'/> Slowed thinking, speaking, and moving</li>
                <li><FaRegStar className='star'/> Trouble concentrating and remembering</li>
                <li><FaRegStar className='star'/> Unexplained physical ailments</li>
                <li><FaRegStar className='star'/> Suicidal thoughts/Thoughts of death</li>
                <li><FaRegStar className='star'/> Feeling worthless, guilt, and/or self-blame</li>
                </ul>
                </p>
                <br/>
                <p>Depression is a common yet serious mental health condition that affects millions of people worldwide. It involves persistent feelings of sadness, hopelessness, and a lack of interest in daily activities. Depression can impact one's ability to function at work, in relationships, and in social settings. Symptoms often include fatigue, changes in appetite, difficulty concentrating, and thoughts of self-harm. It is important to seek professional help to address these symptoms and improve mental well-being.</p>
                <br/>
                <p>There are several forms of depression, including major depressive disorder, persistent depressive disorder (dysthymia), and bipolar disorder, each with varying symptoms and severity. Depression can be caused by a combination of genetic, environmental, and psychological factors. Traumatic life events, chronic stress, or a family history of depression can increase the risk of developing this mental health disorder. Early diagnosis and treatment are crucial for effective management and recovery.</p>
                <br/>
                <p>According to the National Institute of Mental Health (NIMH), depression is one of the leading causes of disability worldwide, with millions of people affected each year. Treatment options for depression include therapy, medication, and lifestyle changes. If you or someone you know is struggling with depression, reaching out to a mental health professional is the first step toward recovery. Seeking help early can significantly improve the chances of managing depression and leading a healthier, more fulfilling life.</p>

</div>

<ContactForm />

    </>
  )
}

export default DepressionSection
