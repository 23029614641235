import React from 'react'
import ContactForm from '../contact/ContactForm'
import { FaRegStar } from 'react-icons/fa';

import './BipolarDisorderSection.css'

const BipolarDisorder = () => {
  return (
<>
<div className="bipolar-section-header container">
                <h1>BIPOLAR DISORDER</h1><br/>
                <p>Also known as manic depression, bipolar disorder is a mental health condition in which one suffers extreme mood swings (mania/hypomania to lows/depression). Episodes of mania may create feelings of euphoria, energy, or irritability.<br/> <br/>
                Episodes of bipolar depression can include symptoms of:<br/><br/>
                <ul>
                <li><FaRegStar className='star'/> Sadness</li>
                <li><FaRegStar className='star'/> Hopelessness</li>
                <li><FaRegStar className='star'/> Loss of pleasure/interest in activities</li>
                <li><FaRegStar className='star'/> Mood swings</li>
                </ul>
                <br/>
                <p>Although these mood swings aren’t frequent, they can cause changes to energy levels, sleep, judgment, decision-making, and overall behavior.</p>
                <br/>
                <ul>
                <li><FaRegStar className='star'/> Stress</li>
                <li><FaRegStar className='star'/> Drug use</li>
                <li><FaRegStar className='star'/> Alcohol abuse</li>
                <li><FaRegStar className='star'/> Irregular sleep patterns</li>
                </ul>
                </p>
                <br/>
                <p>Bipolar disorder is a serious mental health condition that causes extreme mood swings, including emotional highs (mania or hypomania) and lows (depression). These mood changes can significantly affect daily life, relationships, and overall well-being. The disorder impacts the brain’s ability to regulate emotions, often leading to erratic behavior, difficulty with concentration, and changes in energy levels. It’s important to seek professional help for managing bipolar disorder, as it can worsen without treatment.</p>
                <br/>
                <p>Bipolar disorder is typically categorized into two main types: Bipolar I and Bipolar II. Bipolar I is characterized by manic episodes that may last for days or weeks, while Bipolar II involves hypomanic episodes and major depressive episodes. Although the severity of symptoms can vary, both types of bipolar disorder can cause significant disruption in a person's life. Early diagnosis and treatment are key to managing symptoms and preventing episodes from becoming more severe.</p>
                <br/>
                <p>According to the National Institute of Mental Health (NIMH), bipolar disorder affects around 2.8% of the U.S. adult population, making it a common and serious condition. Proper treatment, which may include medication and psychotherapy, is crucial for stabilizing moods and helping individuals lead productive, fulfilling lives. If you or someone you know is experiencing symptoms of bipolar disorder, don’t hesitate to reach out to a mental health professional for support and guidance in managing this condition.</p>

</div>

<div className="contact2-header contact2-container">
        <h1>DO YOU NEED URGENT HELP?</h1>
        <p>Our Addiction and Recovery Support team is available 24 hours a day 7 Days a week.</p>
    </div>

<ContactForm />

    </>
  )
}

export default BipolarDisorder
