import React from 'react'
import ContactForm from '../contact/ContactForm'

import './PTSDSection.css'

const PTSDSection = () => {
  return (
<>
<div className="ptsd-section-header container">
                <h1>POST-TRAUMATIC STRESS DISORDER</h1><br/>
                <p>Post-traumatic stress disorder, or PTSD, is a mental health disorder that can occur after one experiences a traumatic event. The trauma that causes PTSD can either be direct (happens to you) or indirect (witnessing something happen to a loved one). The effects of PTSD can last weeks, months, or even years if not addressed. <br/> <br/>
                This could involve death, natural disaster, combat, sexual violence, or serious injury. The individual may endure flashbacks or nightmares of the traumatic event, and often have heightened levels of anxiety and depression as a result. Veterans return from war with battle trauma. Because of this, it has been known by many names, including ‘shell shock’ and ‘combat fatigue’.<br/><br/>
                Notable groups often affected by PTSD include combat veterans, victims of sexual or physical assault, and survivors of a natural disasters. <br/><br/>
                PTSD can affect anyone, regardless of external factors like age, race, or gender. It is typically more severe if the traumatic event was unexpected, caused by others in your life, or involved a serious perceived threat to your life.
                </p><br/>
                <p>Post-Traumatic Stress Disorder (PTSD) is a mental health condition that can occur after an individual experiences or witnesses a traumatic event. PTSD affects the brain's ability to process and cope with stress, leading to intense emotional and psychological distress. Common symptoms of PTSD include flashbacks, nightmares, anxiety, depression, and emotional numbness. Those suffering from PTSD often struggle with feelings of isolation, guilt, and fear, making it essential for individuals to seek professional treatment and support to manage their symptoms effectively.</p>
                <br/>
                <p>PTSD can develop after a variety of traumatic experiences, such as combat exposure, sexual assault, natural disasters, or the sudden loss of a loved one. The severity of PTSD symptoms can vary, with some individuals experiencing mild symptoms while others may face debilitating effects that interfere with daily life. With the right therapy and support, individuals can begin to heal from trauma and regain control over their lives, but seeking treatment early is crucial for a successful recovery.</p>
                <br/>
                <p>According to the National Institute of Mental Health (NIMH), PTSD affects millions of people worldwide, yet many individuals remain undiagnosed and untreated. Effective PTSD treatment options include therapies such as Cognitive Behavioral Therapy (CBT), exposure therapy, and Eye Movement Desensitization and Reprocessing (EMDR). These evidence-based therapies help individuals process their trauma, reduce anxiety, and learn coping strategies for managing the emotional triggers associated with PTSD. If you or a loved one is struggling with PTSD, seeking help from a trained mental health professional is the first step toward recovery and healing.</p>

</div>
 
<ContactForm />

    </>
  )
}

export default PTSDSection
