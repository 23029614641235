import React from 'react'
import ContactForm from '../contact/ContactForm'
import { FaRegStar } from 'react-icons/fa';

import './KratomSection.css'

const KratomSection = () => {
  return (
<>
<div className="kratom-section-header container">
                <h1>KRATOM ADDICTION</h1><br/>

                <p>Kratom is a plant-derived substance that has gained popularity for its stimulant and pain-relieving effects. However, despite its natural origin, kratom can lead to addiction when used regularly. The active compounds in kratom, primarily mitragynine and 7-hydroxymitragynine, interact with opioid receptors in the brain, causing euphoric effects similar to opioids. Over time, kratom use can reprogram the brain’s reward system, leading to physical and psychological dependence.</p>
                <br/>
                <p>Kratom is typically consumed in powder, capsule, or tea form, and users often rely on it for energy, pain relief, and even to alleviate opioid withdrawal symptoms. While some users believe kratom to be a safe alternative to opioids, its addictive potential and harmful side effects are a growing concern. Regular use of kratom can result in tolerance, dependence, and withdrawal symptoms, making it difficult for individuals to stop without professional treatment.</p>
                <br/>
                <p>Studies and reports indicate that kratom addiction is becoming more prevalent, with users experiencing a range of withdrawal symptoms such as irritability, anxiety, and muscle pain. According to experts, kratom use has been linked to several emergency room visits, highlighting its potential for abuse. For individuals struggling with kratom addiction, seeking treatment is essential to regain control and prevent long-term health consequences. Professional addiction programs can help users break free from kratom dependence through detoxification, therapy, and ongoing support.</p>
                <br/>

                <p>
                Those who use kratom cite both mental and physical health reasons for taking the drug, ranging from anxiety and depression to pain management for their decision to use kratom. Those seeking kratom typically take it as a method of self-medication to treat a variety of predicaments, including:</p><br/>
                <ul>
                <li><FaRegStar className='star'/> Anxiety</li>
                <li><FaRegStar className='star'/> Depression</li>
                <li><FaRegStar className='star'/> Fatigue</li>
                <li><FaRegStar className='star'/> Drug cravings</li>
                <li><FaRegStar className='star'/> Opioid withdrawal</li>
                </ul>
</div>
 
<ContactForm />

    </>
  )
}

export default KratomSection
