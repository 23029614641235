import React from 'react'
import './ServicesMH.css'
import Zoom from 'react-reveal/Zoom';
import { Link as LinkRoll } from "react-scroll";
import { Link } from 'react-router-dom';

import Depressed_Veteran from '../../assets/veteran_depression.webp'
import Anxiety_Veteran from '../../assets/veteran_anxiety.webp'
import PTSD_Veteran from '../../assets/veteran_ptsd.webp'

const ServicesMH = () => {
  return (
    <>
      <div className='servicesMH'>
        <div className='servicesMH-content container'>

            <div className='servicesMH-header'>
                <h1>SERVICES</h1>
                <h2>Mental Health Treatment</h2>
                <p>Bakersfield Rehab provides specialized mental health treatment programs tailored to the unique needs of veterans. Our evidence-based therapies address PTSD, anxiety, depression, and other mental health challenges to promote long-term healing and well-being.</p>
            </div>

            <div className='MH-cards'>

                <Zoom duration={1000}>
                  <div className='MH-cards-contents'>
                    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                      <Link to="/depression">
                        <img src={Depressed_Veteran} alt='Depressed Veteran' loading='lazy'/>
                        <h1>DEPRESSION</h1>
                        <p>At Bakersfield Rehab, we provide compassionate and effective care for veterans battling depression. Our evidence-based treatment programs are designed to address the unique challenges of depression, helping individuals regain control and rediscover hope. </p>
                      </Link>
                    </LinkRoll>
                  </div>
                </Zoom>


                <Zoom duration={2000}>
                  <div className='MH-cards-contents'>
                    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                      <Link to="/anxiety">
                        <img src={Anxiety_Veteran} alt='Depressed Veteran' loading='lazy'/>
                        <h1>ANXIETY</h1>
                        <p>Anxiety can feel overwhelming, but recovery is possible with the right support. At Bakersfield Rehab, we offer specialized anxiety treatment programs tailored to veterans, combining proven therapies and compassionate care to help you regain control and find peace.</p>
                      </Link>
                    </LinkRoll>
                  </div>
                </Zoom>


                <Zoom duration={3000}>

              <div className='MH-cards-contents'>
                  <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                    <Link to="/ptsd">
                      <img src={PTSD_Veteran} alt='Depressed Veteran' loading='lazy'/>
                      <h1>PTSD</h1>
                      <p>Discover effective, veteran-focused PTSD treatment at Bakersfield Rehab. Our specialized programs help veterans overcome post-traumatic stress disorder and regain control of their lives through compassionate, evidence-based care.</p>
                    </Link>
                  </LinkRoll>
                </div>
              </Zoom>

            </div>

              <div className='MH-cards-btn'>
                <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                  <Link to="/mental-health">
                    <button>VIEW MORE</button>
                  </Link>
                </LinkRoll>

              </div>

        </div>

      </div>
    </>
  )
}

export default ServicesMH
