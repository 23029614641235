import React from 'react'
import ContactForm from '../contact/ContactForm'

import './HeroinSection.css'

const HeroinSection = () => {
  return (
<>
<div className="heroin-section-header container">
                <h1>HEROIN ADDICTION</h1><br/>
                <p>Heroin is a powerful and highly addictive opioid that significantly impacts the brain and body. When consumed, heroin quickly crosses the blood-brain barrier and binds to opioid receptors, releasing intense feelings of euphoria. Over time, heroin use alters the brain's chemistry, leading to tolerance, dependence, and addiction. Individuals struggling with heroin addiction may find it incredibly difficult to stop using without professional help due to the severe physical and psychological effects it causes.</p>
                <br/>
                <p>Heroin can be consumed through injection, snorting, or smoking. The most common method of use is injection, which delivers the drug directly into the bloodstream, producing an immediate and powerful effect. Regardless of the method, heroin use comes with serious health risks, including the potential for overdose, infectious diseases, and long-term physical damage. The highly addictive nature of heroin makes it one of the most dangerous substances to abuse.</p>
                <br/>
                <p>According to the National Institute on Drug Abuse (NIDA), heroin addiction has become a major public health crisis, with increasing rates of overdose deaths and emergency room admissions related to heroin use. The physical dependence that develops with prolonged heroin use can lead to withdrawal symptoms, making it difficult for individuals to quit on their own. Effective heroin addiction treatment is essential for breaking the cycle of addiction and helping individuals regain control of their lives.</p>

</div>
 
<ContactForm />

    </>
  )
}

export default HeroinSection
