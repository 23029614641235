import React from 'react'
import ContactForm from '../contact/ContactForm'
import { FaRegStar } from 'react-icons/fa';

import './OpiateSection.css'

const OpiateSection = () => {
  return (
<>
<div className="opiate-section-header container">
                <h1>OPIATE ADDICTION</h1><br/>
                <p>Easily addictive and often associated with synthetic opioids, opiates can be specifically categorized as natural opioids, including: 
                <br/><br/> 
                <ul>
                <li><FaRegStar className='star'/> Opium</li>
                <li><FaRegStar className='star'/> Heroin</li>
                <li><FaRegStar className='star'/> Codeine</li>
                <li><FaRegStar className='star'/> Drug cravings</li>
                <li><FaRegStar className='star'/> Morphine</li>
                </ul><br/> 
                Opiates’ original, medically intended use was for treating pain, however they are highly misused and addictive. Patients that are prescribed or receive opiates for medical remedies can quickly find themselves on a fast path to addiction.<br/><br/>
                Like other addictive substances, addiction is often a vicious cycle, where the user will go to extreme measures to obtain opiates and use them in higher amounts over time to achieve the same feelings or high.
                </p>
                <br/>
                <p>Opiate addiction is a growing epidemic that affects millions of individuals worldwide. Opiates, which include prescription painkillers like oxycodone, hydrocodone, and morphine, as well as illegal substances like heroin, are highly addictive substances that impact the brain's reward system. These drugs create an intense sense of euphoria and pain relief, which leads to the development of tolerance and dependence. Over time, individuals require higher doses to achieve the same effect, increasing the risk of overdose and long-term health complications.</p>
                <br/>
                <p>Opiates are typically consumed through oral ingestion, injection, or smoking, with each method of use leading to rapid addiction. Prescription opioids are often the gateway to heroin use, as many individuals addicted to prescription painkillers turn to illicit substances when their prescriptions run out. The addictive nature of opiates causes both physical and psychological dependence, making it extremely difficult to break free without professional treatment and support.</p>
                <br/>
                <p>According to recent studies, opiate addiction leads to thousands of overdose deaths annually, making it one of the most dangerous forms of substance abuse. Individuals struggling with opiate addiction face a range of health risks, including respiratory depression, organ damage, and mental health disorders. At Bakersfield Rehab, we provide specialized opiate addiction treatment that includes detoxification, therapy, and comprehensive recovery plans tailored to each individual's needs, helping them break free from the cycle of addiction and reclaim their lives.</p>

</div>
 
<ContactForm />

    </>
  )
}

export default OpiateSection
