import React from 'react'
import ContactForm from '../contact/ContactForm'

import './PanicDisorderSection.css'

const PanicDisorderSection = () => {
  return (
<>
<div className="panic-section-header container">
                <h1>PANIC DISORDER</h1><br/>
                <p>A disorder that often develops in early adulthood, Panic Disorder is when an individual may experience unexpected panic attacks (the feeling of being overwhelmed with anxiety or fear, and/or the onset of trembling or rapid heart rate). It is more common in women than men, and usually becomes an issue around your late teen years. <br/> <br/>
                Anyone can have anxiety, stress, and panic regularly. Panic attacks are notably and often mistaken for heart attacks, as many of the physical symptoms are similar between the two. These attacks can happen at any time and often happen more frequently than that, or as little as a few times. <br/><br/>
                A huge part of what makes panic attack disorder so difficult to cope with is a panic disorder’s psychological byproduct of having to live every day in constant fear of the next attack. 
                </p>
                <p>Panic disorder is a serious anxiety condition characterized by sudden and recurrent panic attacks. These intense episodes are marked by overwhelming feelings of fear, anxiety, and a variety of physical symptoms, such as rapid heart rate, shortness of breath, chest pain, and dizziness. Individuals with panic disorder often experience persistent worry about future attacks, which can significantly impact their daily lives and overall well-being.</p>
                <br/>
                <p>People with panic disorder may begin to avoid certain situations or places where they previously experienced a panic attack, leading to social isolation and other behavioral changes. These behaviors can disrupt normal functioning, making it difficult to perform at work or in social settings. Left untreated, panic disorder can escalate and lead to other mental health conditions such as agoraphobia or generalized anxiety disorder.</p>
                <br/>
                <p>Fortunately, panic disorder is highly treatable with a combination of cognitive-behavioral therapy (CBT), medication, and relaxation techniques. Early intervention and personalized treatment plans can help individuals manage panic attacks and improve their quality of life. If you or someone you know is struggling with panic disorder, seeking professional help from a mental health provider can make a significant difference in overcoming this condition and achieving lasting recovery.</p>

</div>
 
<ContactForm />

    </>
  )
}

export default PanicDisorderSection
