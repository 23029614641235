import React from 'react'
import ContactForm from '../contact/ContactForm'

import './PrescriptionsSection.css'

const PrescriptionsSection = () => {
  return (
<>
<div className="prescriptions-section-header container">
                <h1>PRESCRIPTIONS ADDICTION</h1><br/>
                <p>Medication prescriptions are a common and effective treatment for many conditions and health complications. We all experience procedures, body aches, and other discomforts that require medication to ease the pain. Many forms of pain relief are available over the counter, but prescription medication refers specifically to those prescribed by your medical health professional.<br/> <br/>
                This also means that prescription drugs’ potency and addictive qualities when combined with the availability and prevalence of prescription drugs can create a vicious cycle of addiction and physical dependence, if not taken care of properly. And not all prescriptions are created equal; some are more addictive than others. <br/><br/>
                Abuse of prescription drugs can range from the use of stimulants (prescribed for ADHD patients) to sedatives/hypnotics (anxiety/sleep treatments), or opioids (for pain relief). <br/><br/>
                Abuse of prescription medications can be recreational, due to addiction, or both, and the consequences can be severe or fatal.
                </p><br/>
                <p>Prescription drug addiction is a growing epidemic that affects millions of people worldwide. Prescription medications, often used to manage pain or treat anxiety, depression, and other conditions, can be highly addictive when misused. Abuse of prescription drugs alters the brain’s chemistry, leading to physical dependence and addiction. Individuals may develop a tolerance, requiring higher doses to achieve the same effects, which increases the risk of overdose and other severe health complications.</p>
                <br/>
                <p>Prescription drugs can be misused in various ways, including taking higher doses than prescribed, using someone else’s medication, or combining drugs with alcohol or other substances to enhance effects. Commonly abused prescription medications include opioids (such as oxycodone and hydrocodone), benzodiazepines (like Xanax and Valium), and stimulants (such as Adderall and Ritalin). Regardless of the drug, prescription addiction often leads to both physical and psychological dependence, making it difficult for individuals to stop without professional intervention.</p>
                <br/>
                <p>According to the National Institute on Drug Abuse (NIDA), prescription drug abuse and addiction continue to rise, leading to an increasing number of hospitalizations and fatalities. The dangers of prescription addiction extend beyond just the misuse of the medication itself; long-term abuse can result in irreversible damage to the brain, liver, and other vital organs. If you or someone you know is struggling with prescription drug addiction, seeking help from a treatment facility specializing in substance abuse is essential for recovery and long-term health.</p>

</div>
 
<ContactForm />

    </>
  )
}

export default PrescriptionsSection
