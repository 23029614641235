import React from 'react'
import ContactForm from '../contact/ContactForm'

import './AlcoholSection.css'

const AlcoholSection = () => {
  return (
<>
<div className="alcohol-section-header container">
                <h1>ALCOHOL ADDICTION</h1><br/>
                <p>Alcoholism (‘alcohol use disorder’ (AUD)) is the inability to manage one’s drinking habits. It is the most acute form of alcohol abuse. If left untreated,  alcohol abuse can quickly spiral out of control.
                <br/> <br/> Alcoholism is a disease, and consistent alcohol abuse over time can negatively impact career goals, personal relationships, and responsibilities, not to mention your physical health.
                </p><br/>
                <h1>Alcohol Addiction Treatment at Bakersfield Rehab</h1>
                <br/>
                <p>Alcohol addiction is a chronic condition that can severely impact your health, relationships, and overall quality of life. At Bakersfield Rehab, we specialize in providing personalized alcohol addiction treatment to help individuals break free from the cycle of alcohol dependency. Our expert team of professionals offers a range of evidence-based therapies, including detoxification, counseling, and group therapy, all aimed at addressing the underlying causes of alcohol addiction.</p>
                <br/>
                <p>Whether you’re seeking inpatient or outpatient care, Bakersfield Rehab is here to provide you with the support you need. Our alcohol addiction treatment programs are tailored to your specific needs, ensuring a customized approach to recovery. With our compassionate and experienced staff, you’ll receive the tools and support necessary to achieve long-lasting sobriety. Start your recovery journey today at Bakersfield Rehab, where hope, healing, and transformation begin.</p>

</div>

<ContactForm />

    </>
  )
}

export default AlcoholSection
