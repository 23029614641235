import React from 'react'
import ContactForm from '../contact/ContactForm'

import './MethSection.css'

const MethSection = () => {
  return (
<>
<div className="meth-section-header container">
                <h1>METHAMPHETAMINE ADDICTION</h1><br/>
                <p>A highly addictive and extremely dangerous stimulant, meth releases high amounts of dopamine (pleasure/reward chemical in the brain), while simultaneously destroying the receptors in the brain. This is frequently the first stage of a vicious, addictive cycle in which the user needs higher doses to achieve the same euphoric sensation. The lack of delayed gratification and the intoxicating nature of the drug leads to at least – Meth can be used in several ways, most commonly, either snorting, smoking, or injection.<br/><br/>
                The meth addiction crisis in the United States continues to prove that the long-term effects of meth use can be devastating, and range from dental problems, psychosis, brain damage, organ problems, and even death.
                </p>
                <br/>
                <p>Methamphetamine, commonly known as meth, is an extremely addictive stimulant that affects the brain by rapidly increasing dopamine levels. This powerful drug creates intense feelings of euphoria, energy, and alertness, which is why it is highly addictive. Over time, meth use alters the brain’s reward system, making it difficult for individuals to stop using the drug without professional help. The physical and psychological effects of meth addiction are long-lasting and can lead to severe health issues.</p>
                <br/>
                <p>Meth can be consumed in several ways, including smoking, snorting, injecting, or swallowing. Smoking meth produces an immediate and intense "rush," which is why it is the most common method of use. Regardless of how it is used, meth addiction can take hold quickly, leading to dependency and harmful physical and mental consequences. The drug’s effects on the central nervous system contribute to its highly addictive nature and potential for overdose.</p>
                <br/>
                <p>According to the National Institute on Drug Abuse (NIDA), methamphetamine addiction is a growing concern, with millions of people struggling with meth use across the country. The intense cravings and withdrawal symptoms associated with meth addiction make recovery challenging, but with the right treatment and support, individuals can overcome addiction. If you or someone you know is struggling with meth addiction, seeking professional help is critical to achieving long-term sobriety and rebuilding a healthy life.</p>

</div>
 
<ContactForm />

    </>
  )
}

export default MethSection
