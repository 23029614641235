import React from 'react'
import ContactForm from '../contact/ContactForm'

import './AnxietySection.css'

const AnxietySection = () => {
  return (
<>
<div className="anxiety-section-header container">
                <h1>ANXIETY DISORDER</h1><br/>
                <p>As a normal reaction to stress, anxiety generally refers to the worry or fear related to the unknown, the future, or uncertain outcomes of situations<br/> <br/>
                An anxiety disorder is more than just the everyday stress that the average person experiences. Anxiety disorders indicate deeper problems, and <br/><br/>
                When the symptoms of anxiety begin to affect your ability to function and cope with day-to-day life.<br/><br/>
                Those with anxiety disorders look for drugs or alcohol to cope with day-to-day life. This can result in the worsening of symptoms, straining of relationships with others, and the obstruction of performing well at work or school.
                </p><br/>
                <p>Anxiety disorder is a mental health condition that affects millions of people worldwide, characterized by excessive worry, fear, and unease. Unlike normal feelings of stress, anxiety disorder can significantly impact daily life, making it difficult to manage work, relationships, and personal responsibilities. The condition is often linked to an imbalance in brain chemistry and can be triggered by environmental factors, genetics, or past trauma. Early intervention and treatment are key to managing anxiety disorder effectively.</p>
                <br/>
                <p>Symptoms of anxiety disorder can vary widely, but common signs include persistent worry, rapid heartbeat, difficulty concentrating, and sleep disturbances. People with anxiety may also experience panic attacks, which are sudden episodes of intense fear and physical discomfort. The disorder can take several forms, including generalized anxiety disorder (GAD), social anxiety disorder, and panic disorder, each with its own unique set of challenges and treatment needs.</p>
                <br/>
                <p>Effective treatment for anxiety disorder often involves a combination of therapy and medication. Cognitive-behavioral therapy (CBT) is one of the most widely recommended treatments, helping individuals identify and change negative thought patterns that contribute to anxiety. Additionally, medication, such as antidepressants and anti-anxiety drugs, may be prescribed to manage symptoms. If left untreated, anxiety disorder can lead to more serious health problems, making it crucial to seek professional help as soon as possible to regain control of your life.</p>

</div>
 
<ContactForm />

    </>
  )
}

export default AnxietySection
