import React from 'react'
import ContactForm from '../contact/ContactForm'

import './MdmaSection.css'

const MdmaSection = () => {
  return (
<>
<div className="mdma-section-header container">
                <h1>MDMA ADDICTION</h1><br/>
                <p>Commonly referred to as Ecstasy, ‘E’, or ‘Molly’, MDMA is a prevalent street and party drug that gives the user a heightened sense of euphoria, energy, and sexual excitement. <br/> <br/>
                MDMA became more widely used in the 1970s and 80s, at which point the DEA officially placed it on the list of Schedule 1 drugs. Schedule 1 drugs are those that are most potent. <br/><br/>
                MDMA is an empathogen, that is, a drug that increases one’s empathy and compassion towards others. Continued MDMA use appears to affect the body’s dopamine and serotonin levels associated with substance use disorders.<br/><br/> Several factors determine any individual’s reaction to MDMA intake, including other stimulants present in the body, your body’s metabolism, preexisting conditions, the strength of the batch, and the dosage amount.
                </p>
                <br/>
                <p>MDMA, also known as "Ecstasy" or "Molly," is a powerful psychoactive drug that primarily affects serotonin levels in the brain, leading to intense feelings of happiness, emotional closeness, and increased energy. While many people use MDMA recreationally, its euphoric effects can quickly lead to dependence and addiction. Over time, the brain's reward system becomes altered, making it difficult to feel pleasure without the drug, which increases the risk of addiction and long-term health issues.</p>
                <br/>
                <p>MDMA can be consumed in several ways, including swallowing pills, capsules, or snorting the powder form. Regardless of how it is ingested, MDMA causes immediate and intense effects, but it can also have severe consequences. With repeated use, individuals can develop a tolerance, requiring higher doses to achieve the same effects, which exacerbates the cycle of addiction.</p>
                <br/>
                <p>According to addiction experts, MDMA addiction is a growing concern, with many individuals seeking treatment for both physical and psychological dependence. The drug’s effects on serotonin levels can lead to long-term cognitive and emotional issues, including memory loss, depression, and anxiety. As MDMA addiction increases, so does the risk of overdose and other life-threatening conditions, making it critical to seek professional help for recovery.</p>

</div>
   
<ContactForm />

    </>
  )
}

export default MdmaSection
