import React from 'react'
import ContactForm from '../contact/ContactForm'

import './XanaxSection.css'

const XanaxSection = () => {
  return (
<>
<div className="xanax-section-header container">
                <h1>XANAX ADDICTION</h1><br/>
                <p>Xanax is officially classified as a benzodiazepine, a drug used to treat disorders such as anxiety or panic disorders. Like other prescriptions, they are meant for short-term usage, and due to their potency and relieving qualities, addiction can quickly set in.<br/> <br/>
                Also referred to as ‘bars’ ‘Xannies’, and ‘ladders’, Xanax is a prescription drug, but often misused.<br/><br/>
                Although not intended for pain relief, Xanax is relatable to opioids in terms of the user experiencing euphoria and the flushing out of negative feelings or emotions. These experiences/effects alone can lead to an easy path of addiction. It is also commonly abused in combination with substances like alcohol or opioids.<br/><br/>
                When taken in high doses, Xanax can lead to serious side effects, ranging from coma, to respiratory arrest, and even death.
                </p><br/>
                <p>Xanax, a prescription medication commonly prescribed for anxiety and panic disorders, is a powerful benzodiazepine that can lead to addiction when misused. By enhancing the effects of a neurotransmitter called GABA, Xanax produces calming effects that can be highly sought after. However, prolonged use or misuse can cause the brain to become dependent on the drug, leading to physical and psychological addiction. Xanax addiction is a growing concern, as individuals often develop a tolerance, requiring higher doses to achieve the same effects.</p>
                <br/>
                <p>Xanax is typically consumed in pill form, but it can also be crushed and snorted or mixed with other substances for a more intense high. This misuse of Xanax significantly increases the risk of addiction, overdose, and severe health complications. When taken as prescribed, Xanax can be a helpful tool for managing anxiety; however, using it outside of medical guidance can quickly lead to dependence, which requires professional treatment to overcome.</p>
                <br/>
                <p>According to the National Institute on Drug Abuse (NIDA), Xanax addiction has become a serious public health issue, with many individuals seeking emergency care due to overdoses. As tolerance and dependency build over time, withdrawal symptoms can be severe, making it crucial for those struggling with Xanax addiction to seek professional help. Detoxification, therapy, and long-term recovery strategies are necessary to address both the physical and psychological aspects of Xanax addiction and ensure lasting sobriety.</p>

</div>
 
<ContactForm />

    </>
  )
}

export default XanaxSection
