import React from 'react'
import ContactForm from '../contact/ContactForm'
import { FaRegStar } from 'react-icons/fa';

import './SchizoaffectiveSection.css'

const SchizoaffectiveSection = () => {
  return (
<>
<div className="schizoaffective-section-header container">
                <h1>SCHIZOAFFECTIVE DISORDER</h1><br/>
                <p>Schizoaffective is a disorder characterized as delusions or hallucinations present, accompanied by mood disorder symptoms, either:<br/> <br/>
                <ul>
                  <li><FaRegStar className='star'/> Bipolar (episodes of mania and occasional depression)</li>
                  <li><FaRegStar className='star'/> Depressive (depression episodes)</li><br/>
                </ul>
                Schizoaffective episodes tend to vary in length, with some people having repeated episodes. If left untreated, schizoaffective can lead to social problems or difficulties at work and school. <br/><br/>
                This can lead to further problems, like a profound sense of loneliness and setbacks in your professional and personal life (difficulty keeping a job, or even performing daily functions).
                </p><br/>
                <p>Schizoaffective disorder is a serious mental health condition that combines symptoms of schizophrenia, such as hallucinations and delusions, with mood disorder symptoms like depression or mania. This dual diagnosis makes it challenging for individuals to distinguish between mood episodes and psychotic episodes, leading to difficulties in daily functioning. Early diagnosis and treatment are essential to manage the symptoms of schizoaffective disorder and improve quality of life for affected individuals.</p>
                <br/>
                <p>The symptoms of schizoaffective disorder can vary greatly between individuals but generally include mood swings, extreme shifts in energy, and psychotic symptoms such as disorganized thinking or hallucinations. These symptoms can severely impact relationships, work performance, and overall mental health. Schizoaffective disorder can be managed with a combination of medications, including antipsychotics and mood stabilizers, along with psychotherapy to help individuals cope with the emotional and psychological challenges associated with the condition.</p>
                <br/>
                <p>According to the National Institute of Mental Health, schizoaffective disorder affects a significant number of people worldwide, yet it remains underdiagnosed. If left untreated, it can lead to a worsening of symptoms and a decline in mental and physical health. Early intervention with appropriate treatment plans, including professional mental health support and ongoing therapy, is crucial for individuals dealing with schizoaffective disorder to lead fulfilling lives and manage their symptoms effectively.</p>

</div>
 
<ContactForm />

    </>
  )
}

export default SchizoaffectiveSection
