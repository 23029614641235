import React from "react";
import "./Whoweare.css";
import Zoom from 'react-reveal/Zoom';

import { BsCheckLg } from "react-icons/bs";
import HomeSection2Image from "../../assets/whoWeAre1-min.webp";

const Whoweare = () => {
  return (
    <>
      <div className="homeSection2 container">
        <div className="homeSection2-content">
          <div className="homeSection2-left">

            <h1>WHO WE ARE</h1>
            <h2>Empowering Veterans on the Road to Recovery</h2>
            <p>At Bakersfield Rehab, we are dedicated to empowering veterans to reclaim their lives through comprehensive, compassionate care. Our mission is to provide a safe and supportive environment where heroes can heal, grow, and thrive on their journey to recovery.</p>
            <p>
            We understand the unique challenges faced by veterans, from combat-related trauma to the difficulties of transitioning to civilian life. That’s why we specialize in personalized treatment programs tailored to address the mental health and addiction issues that many veterans face. Our team of experienced professionals is committed to guiding each individual toward a healthier and more fulfilling future.
            </p>

            <h1>Why Choose Bakersfield Rehab?</h1>
            <div className="checklist">
              <div className="homeSection2-icon">
                <BsCheckLg /><p>Veteran-Centric Care: We prioritize the specific needs of veterans, creating a community of shared experiences and mutual support.</p>
              </div>
            </div>

            <div className="checklist">
              <div className="homeSection2-icon">
              <BsCheckLg /><p>Comprehensive Services: From evidence-based therapies to holistic wellness programs, we offer a full spectrum of care.</p>
              </div>
            </div>

            <div className="checklist">
              <div className="homeSection2-icon">
                <BsCheckLg />
                <p>Dedicated Team: Our counselors, therapists, and medical staff are deeply committed to helping veterans succeed in their recovery journey.</p>
              </div>
            </div>
            <p>Take the first step toward healing with Bakersfield Rehab. Together, we’ll navigate the path to recovery and build a brighter tomorrow.</p>

          </div>


          <div className="homeSection2-right">
            <Zoom duration={2000}>
              <img src={HomeSection2Image} loading="lazy" alt="psychologist" />
            </Zoom>
          </div>
        </div>
      </div>
    </>
  );
};

export default Whoweare;
