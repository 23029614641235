import React from 'react'
import ContactForm from '../contact/ContactForm'

import './SchizophreniaSection.css'

const SchizophreniaSection = () => {
  return (
<>
<div className="schizophrenia-section-header container">
                <h1>SCHIZOPHRENIA DISORDER</h1><br/>
                <p>You can think of schizophrenia as an abnormal interpretation of reality that does not reflect reality. Schizophrenia disorder is typically characterized by a combination of hallucination, delusion, and extremely disordered thinking, which impair daily life and can be extremely disabling.<br/><br/>
                <h1>SIGNS AND SYMPTOMS</h1><br/>
                Anyone can get schizophrenia. Symptoms usually begin to appear in your late 20s. Diagnosis after about age 45 is rare, as is a diagnosis in children.<br/><br/>
                Symptoms will vary in type and severity most of the time. Most complications from schizophrenia begin with cognitive, behavioral, and emotional changes. The sooner you recognize the signs, the quicker you can begin to treat it and prevent long-term effects.
                </p><br/>
                <p>Schizophrenia is a severe mental health disorder that affects how a person thinks, feels, and behaves. It is characterized by symptoms such as delusions, hallucinations, and disorganized thinking. Individuals with schizophrenia may experience difficulty distinguishing between reality and fantasy, which can lead to challenges in daily functioning. Schizophrenia is often misunderstood, but it is a chronic condition that requires lifelong treatment and management. Early intervention is key to helping those affected by this disorder manage symptoms and improve their quality of life.</p>
                <br/>
                <p>Schizophrenia can develop gradually, typically in late adolescence or early adulthood. It is believed to be caused by a combination of genetic, environmental, and biochemical factors. Brain structure abnormalities and imbalances in neurotransmitters like dopamine and glutamate play a significant role in the onset of schizophrenia. While the exact cause remains unknown, research continues to uncover more about the underlying mechanisms that contribute to this debilitating mental health disorder.</p>
                <br/>
                <p>Treatment for schizophrenia typically includes a combination of antipsychotic medications and psychotherapy. Effective management of schizophrenia requires a comprehensive approach, often involving medication, therapy, and support systems. Cognitive-behavioral therapy (CBT), family therapy, and social skills training can help individuals with schizophrenia better manage their symptoms and integrate into society. At Bakersfield Rehab, we provide specialized care to help individuals living with schizophrenia lead fulfilling and productive lives while managing their condition with professional support.</p>

</div>
 
<ContactForm />

    </>
  )
}

export default SchizophreniaSection
