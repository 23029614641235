import React from 'react'
import ContactForm from '../contact/ContactForm'

import './OpioidSection.css'

const OpioidSection = () => {
  return (
<>
<div className="opioid-section-header container">
                <h1>OPIOID ADDICTION</h1><br/>
                <p>The synthetic version of natural opiates, opioids serve actual medical purposes to relieve acute or temporary pain. When administered properly, opioids relieve physical pain related to ailments, injuries, and medical issues. <br/> 
                <br/> Opioids are a class of drug that includes legal, potent, and frequently misused drugs like fentanyl, illegal drugs like heroin, and legal prescription drugs like hydrocodone, morphine, and oxycodone.<br/> <br/>
                Commonly prescribed and well-known opioids include the drugs Hydrocodone, Oxycodone, Fentanyl, and Methadone, as well as non-prescriptive products such as NyQuil and TheraFlu.
                </p><br/>
                <p>Opioid addiction is a growing public health crisis, affecting millions of individuals worldwide. Opioids, which include prescription painkillers such as oxycodone, hydrocodone, and fentanyl, as well as illicit substances like heroin, are highly addictive substances that can hijack the brain’s reward system. Opioids work by attaching to specific receptors in the brain, blocking pain, and releasing intense feelings of euphoria. Over time, this creates a powerful dependence that leads to addiction, making it difficult to stop using without professional intervention.</p>
                <br/>
                <p>Opioids can be consumed in various forms, including pills, injections, and even smoking. Prescription opioids are often misused when individuals take higher doses than prescribed or use someone else’s medication. This misuse leads to an increased risk of opioid overdose, a serious and potentially fatal outcome. Heroin, a potent illegal opioid, has become a significant contributor to the opioid epidemic, with many individuals transitioning from prescription opioids to heroin due to its lower cost and more immediate effects.</p>
                <br/>
                <p>According to the Centers for Disease Control and Prevention (CDC), opioid addiction is one of the leading causes of overdose deaths in the United States. The widespread abuse of opioids has caused a surge in emergency room visits and overdose deaths, emphasizing the urgency of addressing opioid addiction. Treatment options for opioid addiction, such as medication-assisted treatment (MAT), counseling, and detoxification, are essential for helping individuals break free from opioid dependence and begin their recovery journey.</p>

</div>
 
<ContactForm />

    </>
  )
}

export default OpioidSection
