import React from 'react'
import ContactForm from '../contact/ContactForm'

import './OxycodoneSection.css'

const OxycodoneSection = () => {
  return (
<>
<div className="oxycodone-section-header container">
                <h1>OXYCODONE ADDICTION</h1><br/>
                <p>A powerful substance and one of the significant contributing drugs to the opioid epidemic in the United States.
                <br/> Oxycodone is a synthetic opiate intended to be prescribed to treat mild to intense pain. The only problem; it is also highly addictive and increasingly abused outside of its medicinal purposes. <br/> <br/>
                Oxycodone (also known as ‘Oxy’ or ‘oxies’) is typically consumed in the form of a pill, but can also be crushed up into a powder and injected. Due to its strength, quick effectiveness, and euphoric effects, the user’s body becomes easily dependent on usage, thus starting a vicious cycle of abuse. <br/><br/>
                Even if you begin taking legal oxycodone to fight pain, oxycodone dependance can develop into addiction rapidly as your body depends and demands on the high and your tolerance for the drug increases. <br/><br/>
                Oxycodone delivers a sensation or feeling of euphoria, sedation, reduction of anxiety, and relaxation. It is no coincidence that as the prescriptions of Oxycodone increased in recent years, so too have addiction rates.
                </p>
                <br/>
                <p>Oxycodone is a potent prescription opioid commonly used to treat moderate to severe pain. While effective for pain relief, oxycodone is highly addictive and carries a significant risk of dependency. When misused, oxycodone can cause a rapid release of dopamine in the brain, leading to feelings of euphoria. This reward mechanism contributes to its addictive potential, making it one of the most commonly abused prescription drugs in the United States.</p>
                <br/>
                <p>Oxycodone addiction can occur through various methods, including taking higher doses than prescribed, crushing the pill and inhaling or injecting it, or using it in combination with other substances. Misusing oxycodone in this way can lead to a rapid onset of addiction, as the body quickly develops tolerance, requiring higher doses to achieve the same effects. This dangerous cycle increases the risk of overdose and other serious health complications.</p>
                <br/>
                <p>According to the National Institute on Drug Abuse (NIDA), opioid addiction, including oxycodone, has reached epidemic proportions, leading to thousands of deaths annually. If you or a loved one is struggling with oxycodone addiction, it's essential to seek professional help. Addiction treatment programs tailored to oxycodone addiction can help individuals regain control of their lives and start their journey to recovery with evidence-based therapies designed to address both the physical and psychological aspects of addiction.</p>

</div>
 
<ContactForm />

    </>
  )
}

export default OxycodoneSection
