import React from "react";
import "./ServicesSA.css";
import { CiBeerMugFull } from "react-icons/ci";
import { CiPillsBottle1 } from "react-icons/ci";
import { RiSyringeLine } from "react-icons/ri";
import { FaPills } from "react-icons/fa";
import Fade from "react-reveal/Fade";

import { Link as LinkRoll } from "react-scroll";
import { Link } from "react-router-dom";

const ServicesSA = () => {
  return (
    <>
      <div className="servicesSA">
        <div className="servicesSA-content container">
          <div className="servicesSA-header">
            <Fade left>
              <h1>SERVICES</h1>
            </Fade>
            <Fade right>
              <h2>Substance Abuse Programs</h2>
            </Fade>
            <Fade left>
              <p>
              Bakersfield Rehab offers comprehensive substance abuse programs designed to help individuals overcome addiction and regain control of their lives. Our expert-led treatments provide personalized care and support every step of the way.
              </p>
            </Fade>
          </div>

          <div className="servicesSA-flex">
            <Fade bottom>
              <div className="servicesSA-flexItem">
                <h2>
                  <CiBeerMugFull />
                </h2>
                <h1>Alcohol Addiction</h1>
                <p>Overcome alcohol addiction with Bakersfield Rehab, your trusted partner in recovery. We specialize in providing veterans with personalized, evidence-based treatment programs to reclaim their lives and achieve lasting sobriety. </p>
              </div>
            </Fade>

            <Fade top>
              <div className="servicesSA-flexItem">
                <h2>
                  <CiPillsBottle1 />
                </h2>
                <h1>Prescription Addiction</h1>
                <p>
                Bakersfield Rehab offers specialized programs to help individuals overcome prescription drug addiction and regain control of their lives. Our evidence-based treatments focus on safe detoxification, personalized therapy, and long-term recovery support.
                </p>
              </div>
            </Fade>

            <Fade bottom>
              <div className="servicesSA-flexItem">
                <h2>
                  <RiSyringeLine />
                </h2>
                <h1>Heroin Addiction</h1>
                <p>
                Overcoming heroin addiction starts with expert care and unwavering support. At Bakersfield Rehab, we offer personalized treatment programs to help individuals break free from heroin dependency and reclaim their lives.
                </p>
              </div>
            </Fade>

            <Fade top>
              <div className="servicesSA-flexItem">
                <h2>
                  <FaPills />
                </h2>
                <h1>Xanax Addiction</h1>
                <p>
                At Bakersfield Rehab, we provide specialized treatment programs to help individuals break free from Xanax addiction. Our expert team combines evidence-based therapies and personalized care to support lasting recovery and a healthier future.
                </p>
              </div>
            </Fade>
          </div>

          <div className="servicesSA-btn">
            <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
              <Link to="/substance-abuse">
                <button> VIEW ALL</button>
              </Link>
            </LinkRoll>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesSA;
