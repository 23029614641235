import React from 'react'
import './AboutSection.css'
import ContactForm from '../contact/ContactForm'

import Mission from '../../assets/mission-img1.png'



const AboutSection = () => {
    return (
        <>


            <div className='aboutSection container'>





                        <div className='aboutSectionContent '>


                            <div className='about-section-flex-left'>
                                <div className='aboutSectionCaption'>
                                    <h1> WHO WE ARE </h1>
                                    <p>At Bakersfield Rehab, we are dedicated to empowering veterans to reclaim their lives through comprehensive, compassionate care. Our mission is to provide a safe and supportive environment where heroes can heal, grow, and thrive on their journey to recovery.</p>
                                    <p>We understand the unique challenges faced by veterans, from combat-related trauma to the difficulties of transitioning to civilian life. That’s why we specialize in personalized treatment programs tailored to address the mental health and addiction issues that many veterans face. Our team of experienced professionals is committed to guiding each individual toward a healthier and more fulfilling future.</p>
                                </div>
                            
                            <div className='aboutSectionFlex'>
                                <div className='flexLeftItem'>
                                    <h1><span>01. </span>Support <span> Group</span></h1>
                                    <p>Therapy and support groups for either addiction or mental health disorders</p>
                                </div>

                                <div className='flexLeftItem'>
                                    <h1><span>02. </span>Compassionate <span> Staff</span></h1>
                                    <p>Compassionate staff that is dedicated to your success</p>
                                </div>

                                <div className='flexLeftItem'>
                                    <h1><span>03. </span>Proven <span> Method</span></h1>
                                    <p>Evidence-based practices that give you the best chance of recovery</p>
                                </div>

                                <div className='flexLeftItem'>
                                    <h1><span>04. </span>Job <span> Assistance</span></h1>
                                    <p>Employment tools and resources to get you hired.</p>
                                </div>
                            </div>
                        </div>


                        <div className='flexRight'>
                            <div className='aboutSectionRight'>
                                <img src={ Mission } alt="Woman Therapist" loading="lazy"/>
                            </div>
                        </div>


                </div>

            </div>



        <ContactForm />

</>
    )
}

export default AboutSection
