import React from 'react'
import ContactForm from '../contact/ContactForm'

import './CocaineSection.css'

const CocaineSection = () => {
  return (
<>
<div className="cocaine-section-header container">
                <h1>COCAINE ADDICTION</h1><br/>
                <p>Cocaine is a highly addictive stimulant that significantly impacts the brain by elevating dopamine levels, a neurotransmitter associated with feelings of pleasure and reward. This powerful drug creates an intense sense of euphoria, leading to a strong desire to continue using it. The brain's reward system is reprogrammed over time, which makes it incredibly difficult for individuals to stop using without professional help.</p><br/>

                <p>Cocaine can be consumed in various ways, including snorting, injecting, or smoking. Snorting cocaine is the most common method of use, as it allows the drug to quickly enter the bloodstream and produce immediate effects. Regardless of the method, the drug’s highly addictive nature and rapid onset of effects contribute to the high risk of addiction.</p><br/>

                <p>According to AddictionCenter, cocaine use is a major public health concern, as more people are admitted to emergency rooms for cocaine-related incidents than any other drug. The addictive properties of cocaine, combined with its physical and psychological effects, make it one of the most dangerous substances, leading to both short-term and long-term health risks for users.</p>

</div>

<ContactForm />

    </>
  )
}

export default CocaineSection
