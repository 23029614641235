import React from 'react'
import ContactForm from '../contact/ContactForm'

import './ASDSection.css'

const ASDSection = () => {
  return (
<>
<div className="asd-section-header container">
                <h1>ACUTE STRESS DISORDER</h1><br/>
                <p>Acute Stress Disorder (ASD) is a mental health condition that can develop after an individual experiences a traumatic event. Characterized by symptoms such as intrusive thoughts, flashbacks, and heightened anxiety, ASD can significantly impact daily functioning. Recognizing and addressing these symptoms promptly is crucial for effective treatment and recovery.
                </p>
                <br/>
                <p>Common symptoms of Acute Stress Disorder include:
                  <ul>
                    <li>Intrusive memories or flashbacks of the traumatic event.</li>
                    <li>Persistent anxiety and hypervigilance.</li>
                    <li>Difficulty sleeping and concentrating.</li>
                    <li>Emotional numbness or detachment.</li>
                  </ul>
                </p>
                <br/>
                <p>If you or someone you know is experiencing these symptoms following a traumatic event, it's essential to seek professional help. Early intervention can prevent the progression to Post-Traumatic Stress Disorder (PTSD) and promote healing.</p>
                <br/>
                <p>Treatment options for Acute Stress Disorder often include:
                  <ul>
                    <li>Cognitive Behavioral Therapy (CBT) to address negative thought patterns.</li>
                    <li>Exposure therapy to gradually confront and process traumatic memories.</li>
                    <li>Medication to manage symptoms such as anxiety and depression.</li>
                  </ul>
                </p>
                <br/>
                <p>Consulting with a mental health professional can help determine the most appropriate treatment plan tailored to individual needs.</p>
</div>
 
<ContactForm />

    </>
  )
}

export default ASDSection
