import React from "react";
import "./Whatwedo.css";


const WhatWeDo = () => {
  return (
    <>
      <div className="wrapper1 ">
        <div className="wrapper-content container">
          <div className="whatWeDo-header">
              <h1>What We Do</h1>
              <p>
              At Bakersfield Rehab, we specialize in helping veterans overcome addiction and mental health challenges through tailored, evidence-based programs. Our services include detox, therapy, and holistic treatments designed to promote long-term recovery. We provide a supportive environment where veterans can heal, rebuild, and thrive.
              </p>
          </div>
          
        </div>
      </div>
    </>
  );
};

export default WhatWeDo;
